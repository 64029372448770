import { SET } from './types';

export const SCREENS = {
  MICRO: 'Micro',
  ILLUSTRATION: 'Illustration',
  EMOTION: 'Emotion',
};

export const initialState = {
  buttonTitle: '',
  headerTitle: '',
  pageClickMode: false,
  pageVariant: SCREENS.MICRO,
  genderTitles: {
    title: '',
    subtitle: '',
  },
  targetTitles: {
    title: '',
    subtitle: '',
    text: '',
  },
  targetColor: 'green',
};

export const remoteConfig = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        ...action.payload.cfg,
      };
    default:
      return state;
  }
};
